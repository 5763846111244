import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import products from './products/index.js'
import objVrs from './obj_vrs/index.js'
import objVrScenes from './obj_vr_scenes/index.js'

const state = {}

export default new Vuex.Store({
  state,
  getters: {},
  actions: {},
  mutations: {},
  modules: {
    products,
    objVrs,
    objVrScenes
  }
})
